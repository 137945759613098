import React, { useState } from 'react'
import Gabbung from '../../assets/hero-animation/gabung.png'
import Logo from '../../assets/logo.png'
import Charity from '../../assets/charity-fund.png'
import Marketing from '../../assets/marketing.png'
import LottieImage from '../lottieimage/LottieImage'
import Logo01 from '../../assets/newlogo/logo01.png'
import Logo02 from '../../assets/newlogo/logo02.png'
import logotext from '../../assets/logo-text.png'
import ReactPlayer from 'react-player'

import NftImage from '../../assets/nft/image-02.png'

const StarParalax = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <div className="starparalax-v2">
            {/* <div class="background">
                <img src={backgroundimg} alt="" />
            </div> */}
            <div className="hero-main-content">
                <div className="hero-sub">
                    <div className="container">
                        <div className="hero-header">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="hero-header-text nft-day">
                                        {/* <h1>SAFEPIZZA is now</h1> */}
                                        <a href="https://www.pizzanft.studio/" target="_blank"><img src={logotext} alt="" className="img-responsive" /></a>

                                        {/* <h1>NFT OF THE DAY</h1> */}
                                        {/* <h2>CREATE, BUY, BID, SELL OR TRADE NFTS IN BINANCE SMART CHAIN</h2> */}
                                        {/* <a href="http://nft.pizza-nft.com/" target="_blank">SIGN UP FOR BULLETINS</a> */}
                                    </div>
                                    <div className="nft-image-main">
                                        <div class="photo">
                                            <img src={NftImage} alt="" />
                                            {/* <div className="video-container-main">
                                                <ReactPlayer
                                                    loop={true}
                                                    playing={true}
                                                    height={700}
                                                    muted={true}
                                                    url='https://streamable.com/xzdzvk' />
                                            </div> */}
                                            <div class="nft-text-wrap">
                                                <h3>
                                                    Astro Warriors Hot Pink Lava Bot Tron 5006
                                                </h3>
                                                {/* <h4>15BNB</h4> */}
                                                <a href="https://pizzanft.studio/details/568" target="_blank">Collect</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StarParalax

import React from 'react'
import TextBng from '../../assets/text-bnb.svg'
import BnbMobile from '../../assets/bnb-mobile.png'


const ButtonSet = () => {
    return (
        <>
            <div className="container">
                <div className="youtube-video">
                    <div className="main-video">
                        <iframe width="100%" height="400" src="https://www.youtube.com/embed/yONALgUmAiY?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>



                    </div>
                </div>
            </div>
        </>
    )
}

export default ButtonSet
